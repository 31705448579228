import React from "react";
import SimpleLayout from "../components/SimpleLayout";

const ImpressumPage = () => (
	<SimpleLayout>
		<h1>Impressum</h1>
		<p>Lokodin GmbH</p>
		<p>
			Ortmattstr. 3b
			<br />
			<span>
				79541 Lörrach
				<br />
			</span>
			Deutschland
			<br />
			<br />
			<span>Telefon: +49 7621-916-5290</span> <br />
			<br />
			E-Mail: post@lokodin.com
		</p>
		<p>Amtsgericht Freiburg HRB 719579</p>
		<p>Geschäftsführer: Ellis Whitehead</p>
	</SimpleLayout>
);

export default ImpressumPage;
