import React, { ReactNode } from "react";
import Layout from "../components/layout";

interface SimpleLayoutProps {
	children: ReactNode;
}

const SimpleLayout = ({ children }: SimpleLayoutProps) => (
	<Layout>
		<div style={{ width: "50em", margin: "0 auto", display: "block" }}>{children}</div>
	</Layout>
);

export default SimpleLayout;
